/* 
|--------------------------------------------------------------------------
| Fonctions
|--------------------------------------------------------------------------
|
| Les divers scripts du site.
*/

import { OBSERVER } from '../main'
import anime from 'animejs/lib/anime.es.js'
import { getElementOffset } from './helper'


// Fonction ajoutant l'événement 'click' qui appellera la fonction 'scrollToBlock'
export const clickToScrollToBlock = (options) => {
  options.duration === undefined ? options.duration = 800              : options.duration
  options.root     === undefined ? options.root     = document         : options.root
  options.scrollTo === undefined ? options.scrollTo = 'html, body'     : options.scrollTo
  options.easing   === undefined ? options.easing   = 'easeInOutQuart' : options.easing
  options.offset   === undefined ? options.offset   = 0 : options.offset

  if (!document.querySelector(options.scrollTo)) return

  const onClick = () => scrollToBlock({
    scrollTo: options.scrollTo,
    duration: options.duration,
    easing: options.easing,
    offset: options.offset,
    root: options.root
  })
  
  OBSERVER.add({
    name: 'scrollToBlock',
    event: 'click',
    target: options.selector,
    root: options.root,
    function: onClick
  })

  OBSERVER.on('scrollToBlock')
}


// Fonction exécutant l'animation du scroll vers son bloc
export const scrollToBlock = (options = {}) => {
  options.duration === undefined ? options.duration = 800              : options.duration
  options.root     === undefined ? options.root     = document         : options.root
  options.scrollTo === undefined ? options.scrollTo = 'html, body'     : options.scrollTo
  options.easing   === undefined ? options.easing   = 'easeInOutQuart' : options.easing
  options.offset   === undefined ? options.offset   = 0 : options.offset
  
  const element = typeof unknownElement === 'string' ? options.root.querySelector(options.scrollTo) : options.scrollTo
  if (!element) return
  const scrollbar = window.document.scrollingElement || window.document.body || window.document.documentElement

  const animation = anime.timeline({
    targets: scrollbar,
    duration: options.duration,
    easing: options.easing 
  }).add({ scrollTop: getElementOffset({ element: element, root: options.root }).top + options.offset })
  
  return animation.finished
}


export const fancyboxCapsule = () => {
  if (!document.querySelector('.capsules-slider')) return
  $('.slick-slide:not(.slick-cloned) .capsules-slider__link').fancybox({
    selector : '.slick-slide:not(.slick-cloned) .capsules-slider__link',
    loop: true,
    animationEffect: 'fade',
    backFocus : false,
  })
}

export const initBackToTop = (options = {}) => {
  options.root === undefined ? options.root  = document : options.root
  
  OBSERVER.add({
    name: 'manageBackToTop',
    event: 'scroll',
    root: options.root,
    function: manageBackToTop
  })

  clickToScrollToBlock({ selector: '.js-back-to-top', scrollTo: 'html, body' })
  manageBackToTop()

  OBSERVER.on('manageBackToTop')
}

function manageBackToTop(){
  let headerVisible = document.documentElement.scrollTop <= 600;
  if(!headerVisible){
    document.querySelector('html').classList.add("show-back-to-top")
  }else{
    document.querySelector('html').classList.remove("show-back-to-top")
  }
}

export function copyTextToClipboard(text) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text)
    return
  }
  navigator.clipboard.writeText(text).then(function() {
    console.log('Async: Copying to clipboard was successful!')
  }, function(err) {
    console.error('Async: Could not copy text: ', err)
  })
}

function fallbackCopyTextToClipboard(text) {
  let pos = $(document).scrollTop()

  let textArea = document.createElement('textarea')
  textArea.value = text
  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()

  try {
    document.execCommand('copy')
  } catch (err) { console.log(err)}

  document.body.removeChild(textArea)
  $(document).scrollTop(pos)
}

// Ajouter des marges pour les <p> qui contiennent des boutons
export function adjustButtonsMargins() {
  $('.dynamic p > a.button').each(function() {
    $(this).parent().addClass('buttonMargin')
  })
  $('.dynamic p.buttonMargin').each(function() {
    if(!($(this).prev().hasClass('buttonMargin'))){
      $(this).addClass('buttonMargin--first')
    }
    if(!($(this).next().hasClass('buttonMargin'))){
      $(this).addClass('buttonMargin--last')
    }
  })
}

// Ajouter les icones svg pour les boutons et les liens textes
export function addSvgToLinks() {

  let linksExternal = document.querySelectorAll('.dynamic a[target=_blank]:not([href$=".pdf"]):not([href$=".doc"]):not([href$=".zip"])')
  linksExternal.forEach(function(link) {
    var svgElem = document.createElementNS('http://www.w3.org/2000/svg', 'svg'),
    useElem = document.createElementNS('http://www.w3.org/2000/svg', 'use')
    useElem.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', '/themes/core/assets/medias/images/icons/symbols.svg#ico-external')
    svgElem.appendChild(useElem);
    link.appendChild(svgElem)
  })

  let linksDocuments = document.querySelectorAll('.dynamic a[href$=".pdf"], .dynamic a[href$=".doc"], .dynamic a[href$=".zip"]')
  linksDocuments.forEach(function(link) {
    var svgElem = document.createElementNS('http://www.w3.org/2000/svg', 'svg'),
    useElem = document.createElementNS('http://www.w3.org/2000/svg', 'use')
    useElem.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', '/themes/core/assets/medias/images/icons/symbols.svg#ico-download')
    svgElem.appendChild(useElem);
    link.appendChild(svgElem)
  })

}

//
export function fixDocLinks() {

  $('a.a-style-document').each(function(){
    let p = $(this).parent()
    $(this).insertBefore(p);
    p.remove();
  })
}


export function wrapYtVideo(){
  $('iframe[src*="youtube.com"]').wrap( '<div class=\'videoWrapper\'><div class=\'ytVideo\'></div></div>' );
}