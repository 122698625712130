/*
|--------------------------------------------------------------------------
| Sliders
|--------------------------------------------------------------------------
|
| Création de sliders.
*/


// Le slider des chantiers
export function siteSlider() {
  if (!document.querySelector('.site-slider__slider')) return
  $('.site-slider__slider').slick({
    arrows: false,
    dots: false,
    infinite: true,
    autoplay: false,
    draggable: true,
    swipeToSlide: true,
    centerMode: true,
    slidesToShow: 1,
    speed: 500,
    autoplaySpeed: 5000,

    responsive: [{
      breakpoint: 850,
      settings: { centerMode: false }
    }]
  })

  $('.site-slider__arrow--prev').on('click', () => {
    $('.site-slider__slider').slick('slickPrev')
  })
  
  $('.site-slider__arrow--next').on('click', () => {
    $('.site-slider__slider').slick('slickNext')
  })
}


// Le slider des chantiers
export function capsulesSlider() {
  if (!document.querySelector('.capsules-slider__slider')) return
  $('.capsules-slider__slider').slick({
    arrows: false,
    dots: false,
    infinite: true,
    autoplay: false,
    draggable: true,
    swipeToSlide: true,
    centerMode: true,
    slidesToShow: 1,
    speed: 500,
    swipeToSlide: true,

    responsive: [{
      breakpoint: 850,
      settings: { centerMode: false }
    }]
  })

  $('.capsules-slider__arrow--prev').on('click', () => {
    $('.capsules-slider__slider').slick('slickPrev')
  })
  
  $('.capsules-slider__arrow--next').on('click', () => {
    $('.capsules-slider__slider').slick('slickNext')
  })

  fixSlickAnim('.capsules-slider__slider')
}


const fixSlickAnim = (slider) => {
  $(slider).on('beforeChange', (event, slick, currentSlide, nextSlide) => {
    let direction, slideCountZeroBased = slick.slideCount - 1

    if (nextSlide == currentSlide)
      direction = 'same'
    else if (slick.currentDirection == 1)
      direction = 'left'
    else
      direction = 'right'

    if (direction == 'right')
      $('.slick-cloned[data-slick-index="' + (nextSlide + slideCountZeroBased + 1) + '"]', $(slider)).addClass('slickAnimate')

    if (direction == 'left')
      $('.slick-cloned[data-slick-index="' + (nextSlide - slideCountZeroBased - 1) + '"]', $(slider)).addClass('slickAnimate')
  })

  $(slider).on('afterChange', () => {
    $('.slickAnimate', $(slider)).removeClass('slickAnimate')
    $('.slickAnimate', $(slider)).removeClass('slickAnimate')
  })
}


// Fonction permettant de détruire un carousel Slick
export function destroySlick() {
  if ($('.slick-initialized').length > 0)
    $('.slick-initialized').slick('unslick')
}