import anime from 'animejs/lib/anime.es.js'

export const newsList = () => {
  if (!document.querySelector('.js-news-list-page')) return

  // La ligne de la page actus liste
  anime.timeline({
  }).add({
    targets: '.js-news-list-line',
    strokeDashoffset: [anime.setDashoffset, 0],
    easing: 'easeInSine',
    duration: 1800,
    delay: 700,
  })

  // La shape du title-on-shape
  const titleOnShapeStates = [
    { d: "M 15.4 49.6 C 5.6 60.8 -0.1 74.6 2.6 91.6 C 3.9 99.9 11.7 110.2 16.4 117.2 C 22.4 129 15.9 149.6 35 155.5 C 43.4 158.1 83.3 167.1 92.2 166 C 121.4 162.4 141.8 151 154.3 121.2 C 160.6 106.1 161.5 89.4 160.9 73 C 160 51.3 163.1 21.6 143.6 8.9 C 120.7 -6 78.6 11.9 56.4 21.8 C 40.9 28.8 27 36.4 15.4 49.6 Z" },
    { d: "M 5.6 78.7 C 0.1 92.2 -0.8 106.8 7.2 121.7 C 11.1 129 17.5 134.5 24.1 139.5 C 39.2 150.9 61.3 167.5 81 166.9 C 89.7 166.6 98 163 105.9 159.1 C 131.9 146.4 158.3 129.2 160.3 97.5 C 161.3 81.5 156.7 65.7 150.9 50.7 C 143.1 30.9 131 7.9 108.7 2.4 C 82.6 -4.1 54.6 20.5 37.1 36.9 C 25 48.3 12.1 62.7 5.6 78.7 Z" }
  ]

  anime({
    targets: '.js-title-on-shape-path',
    d: [
      { value: titleOnShapeStates[0].d },
      { value: titleOnShapeStates[1].d }
    ],
    duration: 15955,
    direction: 'alternate',
    easing: 'easeInOutSine',
    autoplay: true,
    loop: true
  });

  anime({
    targets: '.title-on-shape__shape',
    scale: 1.02,
    rotate: '30deg',
    translateY: '-10px',
    translateX: '-20px',
    duration: 20364,
    direction: 'alternate',
    easing: 'easeInOutSine',
    autoplay: true,
    loop: true
  });
}