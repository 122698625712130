import anime from 'animejs/lib/anime.es.js'
import { OBSERVER } from '../main'
import { clickToScrollToBlock } from '../functions/functions'

export const pageHome = () => {
  if (!document.querySelector('.js-page-home')) return

  clickToScrollToBlock({ selector: '.js-scroll-selector', scrollTo: '.js-scroll-destination' })


  // La ligne sur la bannière
  anime.timeline({
  }).add({
    targets: '.js-banner-line',
    strokeDashoffset: [anime.setDashoffset, 0],
    easing: 'easeOutSine',
    duration: 1800,
    delay: 700,
  })


  // La shape de la bannière
  const bannerShapeStates = [
    { d: "M 933.8 0 L 933.8 650.3 L 0 650.3 C 0 650.3 14.6 517.4 149.3 479.2 C 284 441 363.8 570.8 502.4 456.9 C 641 343 577.8 224.8 673.4 137.4 C 769 50 933.8 0 933.8 0 Z" },
    { d: "M 933.8 67 L 933.8 650.3 L 68 650.3 C 68 650.3 60.2 472 200 480 C 287 485 462.4 574.9 601 461 C 739.6 347.1 564.4 157.3 661 71 C 717 21 933.8 67 933.8 67 Z" }
  ]

  anime({
    targets: '.banner__img--shape path',
    d: [
      { value: bannerShapeStates[0].d },
      { value: bannerShapeStates[1].d }
    ],
    duration: 10000,
    direction: 'alternate',
    easing: 'easeInOutSine',
    autoplay: true,
    loop: true
  });


  // La shape du image-with-text
  const imageWithTextShapeStates = [
    { d: "M 78.9 1.9 C 64.6 -1.7 49.8 -0.7 35.8 9.4 C 29 14.3 24.3 21.6 20.2 28.9 C 10.8 45.6 -2.8 70.2 0.5 90 C 2 98.8 6.8 106.6 11.8 113.9 C 28.2 138.3 49.1 162.6 81.3 160.1 C 97.5 158.9 112.8 152.1 127.1 144.2 C 146 133.8 167.5 118.4 170 95.2 C 172.9 68 144.3 43.2 125.4 27.9 C 112.1 17.3 95.9 6.3 78.9 1.9 Z" },
    { d: "M 115.1 13.2 C 104.7 4.6 92.1 -0.4 76.5 2.7 C 71 4.3 63.7 10.2 55.9 13 C 41.5 23.4 16 18.1 10.9 36 C 8.7 44 9.5 52.4 10.7 60.5 C 14.6 87.3 -7.3 121.7 20.3 132.1 C 34.1 137.4 63.7 147.1 80.4 153.4 C 100.1 156.9 148.8 165.5 160.1 147.1 C 173.2 125.5 159.5 93.6 150 73.3 C 143.2 59.3 127.3 23.5 115.1 13.2 Z" }
  ]

  anime({
    targets: '.js-small-path',
    d: [
      { value: imageWithTextShapeStates[0].d },
      { value: imageWithTextShapeStates[1].d }
    ],
    duration: 12955,
    direction: 'alternate',
    easing: 'easeInOutSine',
    autoplay: true,
    loop: true
  });

  anime({
    targets: '.image-with-text__shape',
    scaleX: 0.9,
    scaleY: 1.1,
    rotate: '35deg',
    translateX: '20px',
    duration: 7539,
    direction: 'alternate',
    easing: 'easeInOutSine',
    autoplay: true,
    loop: true
  });

  anime({
    targets: '.title-next-to-text__shape',
    scale: 1.2,
    rotate: '20deg',
    translateY: '-20px',
    duration: 6539,
    direction: 'alternate',
    easing: 'easeInOutSine',
    autoplay: true,
    loop: true
  });

  anime({
    targets: '.news-featured__shape',
    scale: 1.1,
    rotate: '30deg',
    translateY: '-30px',
    duration: 8539,
    direction: 'alternate',
    easing: 'easeInOutSine',
    autoplay: true,
    loop: true
  });

  // La shape du title-on-shape
  const capsuleShapeStates = [
    { d: "M8.9,57.7c1.5,8.6,1.8,20.9,2.4,29.7c0.9,11.8-3.7,23.1-6.6,34.6c-2.8,11.5,4.5,21.4,12.3,30.3c10.3,11.8,21.2,13,36.6,9.7 c16.3-3.5,15.8,3.6,32.2,0c8.7-1.8,5-17.8,20.7-19.9c14.8-7.1,14.8-5.3,22.8-34.1C142.3,56.5,100-40.2,40.6,20.4 C35.9,25.7,6,41.4,8.9,57.7z" },
    { d: "M34,42.6c-1.9,8.5-1.6,17.5-4.4,25.8c-3.7,11.2-12.4,19.9-19.5,29.3c-7,9.5-12.7,21.6-9,32.8c4.9,14.9,23.1,20.1,38.5,23 c16.4,3.1,32.8,6.2,49.3,9.3c8.7,1.7,18.2,3.2,26.1-1c10-5.3,13.5-17.6,15.3-28.7C137.7,89,142.9-27.1,64.7,5.8 C49.4,12.2,37.6,26.4,34,42.6z" }
  ]

  anime({
    targets: '.js-capsule-path',
    d: [
      { value: capsuleShapeStates[0].d },
      { value: capsuleShapeStates[1].d }
    ],
    duration: 15955,
    direction: 'alternate',
    easing: 'easeInOutSine',
    autoplay: true,
    loop: true
  });

  // La shape du title-on-shape
  const titleOnShapeStates = [
    { d: "M 15.4 49.6 C 5.6 60.8 -0.1 74.6 2.6 91.6 C 3.9 99.9 11.7 110.2 16.4 117.2 C 22.4 129 15.9 149.6 35 155.5 C 43.4 158.1 83.3 167.1 92.2 166 C 121.4 162.4 141.8 151 154.3 121.2 C 160.6 106.1 161.5 89.4 160.9 73 C 160 51.3 163.1 21.6 143.6 8.9 C 120.7 -6 78.6 11.9 56.4 21.8 C 40.9 28.8 27 36.4 15.4 49.6 Z" },
    { d: "M 5.6 78.7 C 0.1 92.2 -0.8 106.8 7.2 121.7 C 11.1 129 17.5 134.5 24.1 139.5 C 39.2 150.9 61.3 167.5 81 166.9 C 89.7 166.6 98 163 105.9 159.1 C 131.9 146.4 158.3 129.2 160.3 97.5 C 161.3 81.5 156.7 65.7 150.9 50.7 C 143.1 30.9 131 7.9 108.7 2.4 C 82.6 -4.1 54.6 20.5 37.1 36.9 C 25 48.3 12.1 62.7 5.6 78.7 Z" }
  ]

  anime({
    targets: '.js-title-on-shape-path',
    d: [
      { value: titleOnShapeStates[0].d },
      { value: titleOnShapeStates[1].d }
    ],
    duration: 15955,
    direction: 'alternate',
    easing: 'easeInOutSine',
    autoplay: true,
    loop: true
  });

  anime({
    targets: '.title-on-shape__shape',
    scale: 1.02,
    rotate: '30deg',
    translateY: '-10px',
    translateX: '-20px',
    duration: 20364,
    direction: 'alternate',
    easing: 'easeInOutSine',
    autoplay: true,
    loop: true
  });

  const onScrollfire = (e) => {
    // La ligne du slider des chantiers
    if (e.detail.element.id == 'siteSlider') {
      anime.timeline().add({
        targets: '.js-site-slider-line',
        strokeDashoffset: [anime.setDashoffset, 0],
        easing: 'easeInSine',
        duration: 2000,
        delay: 500,
      })
    }

    // La ligne des nouvelles
    if (e.detail.element.id == 'newsFeatured') {
      anime.timeline().add({
        targets: '.js-news-featured-line',
        strokeDashoffset: [anime.setDashoffset, 0],
        easing: 'easeInSine',
        duration: 2000,
        delay: 500,
      })
    }
  }

  OBSERVER.add({
    name: 'scrollfire',
    event: 'scrollfire',
    function: onScrollfire
  })

  OBSERVER.on('scrollfire')
}