/*
|--------------------------------------------------------------------------
| Popup et sidepanel
|--------------------------------------------------------------------------
|
| Ici on retrouve tout ce qui a attrait aux popups et au sidepanels.
*/


import Overlay from '../../libraries/overlay.js'
import { copyTextToClipboard, calendar } from './functions.js'


// Fonction gérant l'overlay Menu
export const overlayMenu = () => {
  let menu = new Overlay({
    name: 'menu',
    click: { buttons: { toggle: '.menu-button', close: 'a[href]' }},
    options: { 
      speed: 800,
      closeOnResize: false
    }
  })

  menu.init()
}

// Fonction gérant l'overlay de partage
export const overlayShare = (root = document) => {

  if (typeof __sharethis__ !== 'undefined' && __sharethis__.config) {
    __sharethis__.init(__sharethis__.config)
  }

  let share = new Overlay({
    name: 'share',
    click: {
      buttons: {
        open: '.js-open-overlay-share',
        close: '.close-overlay',
      }
    },
    options: {
      speed: 800,
      root: root
    }
  })

  share.init()
  
  $(root).find('#copyShareBtn').click( function() {
    let link = $(this).attr('data-url')
    copyTextToClipboard(link)
    copyTextToClipboard(window.location.href)
    alert('Un url a été copié sur votre presse-papiers.')
    return false
  })

  $(root).find('.rubric-share').on('click', function(e) {
    window.history.pushState(null, null, '#' + e.currentTarget.dataset.rubric)
    //pour le partage par courriel
    $(root).find('#emailShareBtn').attr('href', 'mailto:?Subject=Plateforme municipale pour le climat&body=' + window.location.href)
    //pour le partage par facebook ou twitter
    $(root).find('#facebookShareBtn').attr('data-url', window.location.href)
    $(root).find('#twitterShareBtn').attr('data-url', window.location.href)
    $(root).find('#linkedinShareBtn').attr('data-url', window.location.href)
  })

  //clean l'url quand on ferme l'overlay de share
  $(root).find('.overlay-share__background, .overlay-share__close').on('click', function() {
    window.history.replaceState(null, null, ' ')
    //remettre le partage de la page (sans #)
    $(root).find('#emailShareBtn').attr('href', 'mailto:?Subject=Plateforme municipale pour le climat&body=' + window.location.href)
    $(root).find('#facebookShareBtn').attr('data-url', window.location.href)
    $(root).find('#twitterShareBtn').attr('data-url', window.location.href)
    $(root).find('#linkedinShareBtn').attr('data-url', window.location.href)
  })
}
