/*
|--------------------------------------------------------------------------
| Helper
|--------------------------------------------------------------------------
|
| Les fonctions réutilisable qui ne changeront jamais d'un site à un autre.
*/

export function forceBlankOnExterneAndPdfLinks() {
  const anchors = document.querySelectorAll('a[href]')
  for (var i = 0; i < anchors.length; i++) {

    if (anchors[i].target != '_blank' && anchors[i].href != 'javascript:;')
    {
      if (window.location.hostname !== anchors[i].hostname || anchors[i].href.match('\\.pdf$') || window.location.protocol !== anchors[i].protocol)
      {
        anchors[i].setAttribute('target', '_blank')
      }
    }
  }
}


// Retourne la position d'un élément dans le DOM
export const getElementOffset = (options) => {
  options.root === undefined ? options.root = document : options.root
  let box = typeof options.element === 'string' ? options.root.querySelector(options.element).getBoundingClientRect() : options.element.getBoundingClientRect()

  let body = document.body
  let docEl = document.documentElement

  let scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop
  let scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft

  let clientTop = docEl.clientTop || body.clientTop || 0
  let clientLeft = docEl.clientLeft || body.clientLeft || 0

  let top  = box.top +  scrollTop - clientTop
  let left = box.left + scrollLeft - clientLeft

  return { top: Math.round(top), left: Math.round(left) }
}


// Retire tous les effets d'over sur le mobile
export function removeHoverOnMobile() {
  if (/Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    try { // prevent exception on browsers not supporting DOM styleSheets properly
      for (var si in document.styleSheets) {
        var styleSheet = document.styleSheets[si]
        if (!styleSheet.rules) continue

        for (var ri = styleSheet.rules.length - 1; ri >= 0; ri--) {
          if (!styleSheet.rules[ri].selectorText) continue
          if (styleSheet.rules[ri].selectorText.match(':hover')) styleSheet.deleteRule(ri)
        }
      }
    } catch (ex) { console.error(ex) }
  }
}


// Retourne si l'utilisateur est sur mobile ou pas
export const isMobile = () => {
  let isMobile = false

  if ((/Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)))
    isMobile = true

  return isMobile
}

// Diminue la taille du H1 si le texte est trop long
export function sizeH1() {
  let i
  let h1 = document.querySelectorAll('h1, .h1')
  let h1Length = h1.length

  for(i=0; i<h1Length; i++) {
    if(h1[i].textContent.length > 75){
      h1[i].classList.add('much-smaller')
    } else if(h1[i].textContent.length > 35) {
      h1[i].classList.add('smaller') 
    } 
  }
}
