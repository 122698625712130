import anime from 'animejs/lib/anime.es.js'
import { clickToScrollToBlock } from '../functions/functions'

export const pageTitle = () => {
  if (!document.querySelector('.js-page-title')) return

  clickToScrollToBlock({ selector: '.js-scroll-selector', scrollTo: '.js-scroll-destination' })

  anime.timeline({
  }).add({
    targets: '.js-page-title-line',
    strokeDashoffset: [anime.setDashoffset, 0],
    easing: 'easeInSine',
    duration: 2200,
    delay: 0,
  })

  // La shape du page title
  const pageTitleShapeStates = [
    { d: "M 86.7 80.4 L 93.9 7.6 C 93.6 7.8 91.8 7.6 91.5 7.8 C 80.8 15.7 68.6 20.9 60.6 31.6 C 45.2 52.1 18.9 60.8 5.9 83 C 5.1 84.4 1.3 86.9 0.7 88.4 L 86.7 80.4 Z" },
    { d: "M 86.7 80.4 L 86.7 0 C 86.4 0.2 86.1 0.4 85.8 0.6 C 75.1 8.5 73.5 23.9 65.5 34.6 C 50.1 55.1 15.1 53.8 2.1 76 C 1.3 77.4 0.6 78.9 0 80.4 L 86.7 80.4 Z" }
  ]

  anime({
    targets: '.page-title__shape path',
    d: [
      { value: pageTitleShapeStates[0].d },
      { value: pageTitleShapeStates[1].d }
    ],
    duration: 10000,
    direction: 'alternate',
    easing: 'easeInOutSine',
    autoplay: true,
    loop: true
  });
}