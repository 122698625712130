/*
|--------------------------------------------------------------------------
| Formulaires et validation
|--------------------------------------------------------------------------
|
| Validation, messages d'erreur, formatage de champs et divers à propos des
| formulaires, c'est ici !
*/

import { OBSERVER } from "../main"
import {  isMobile } from './helper'


// Retourne le nombre d'octets formaté
const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 ko'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Octets', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}


// Permet de changer le label des input files
export const fileUpload = (root = document) => {
  if (!document.querySelector('.field--file')) return

  const clear = (i, element, currentLabelText) => {
    element.value = ''
    element.nextElementSibling.querySelector('.field__text').innerText = currentLabelText
    element.parentNode.dataset['file'] = ''
    OBSERVER.off(`clear${i}`)
  }

  const changeLabel = (e) => {
    const self = e
    const label = e.currentTarget.nextElementSibling.querySelector('.field__text')
    const currentLabelText = label.innerText
    let i, newLabel = '', fileLength = e.currentTarget.files.length

    if ('files' in e.currentTarget) {
      if (fileLength !== 0) {
        for (i=0; i<fileLength; i++) {
          let file = e.currentTarget.files[i]
          newLabel += `${(i+1)}. `

          if ('name' in file) newLabel += `fichier: ${file.name}, `
          if ('size' in file) newLabel += `poids: ${formatBytes(file.size)} \n`

          const onClear = () => clear(i, self.target, currentLabelText)

          OBSERVER.add({
            name: `clear${i}`,
            event: 'click',
            target: e.currentTarget.previousElementSibling,
            root: root,
            function: onClear
          })

          OBSERVER.on(`clear${i}`)
        }
        e.currentTarget.parentNode.dataset['file'] = newLabel
        label.innerText = newLabel
      }
    }
  }

  OBSERVER.add({
    name: 'fileUpload',
    event: 'change',
    target: 'input[type=file]',
    root: root,
    function: changeLabel
  })
  
  OBSERVER.on('fileUpload') 
}


// Ajoute un attribut en considérant si le input est plein ou vide
export const inputsAndTextareaLabel = () => {
  const selectors = 'input[type="text"], input[type="email"], input[type="search"], textarea'
  if (!document.querySelector(selectors)) return
  const elementsString = selectors,
    formElements = document.querySelectorAll(elementsString),
    formElementsLength = formElements.length
  let i
  
  for (i = 0; i < formElementsLength; i++)
    formElements[i].parentNode.dataset[formElements[i].tagName.toLowerCase() + 'value'] = formElements[i].value

  const setValue = (e) => e.target.parentNode.dataset[e.target.tagName.toLowerCase() + 'value'] = e.target.value

  OBSERVER.add({
    name: 'inputsAndTextareaLabel',
    event: 'input',
    target: elementsString,
    function: setValue
  })

  OBSERVER.on('inputsAndTextareaLabel')
}


// Modification de la hauteur d'un textarea selon son contenu
export const textareaHeight = () => {
  if (!document.querySelector('textarea')) return
  const onInput = (e) => {
    e.currentTarget.style.height = '5px'
    e.currentTarget.style.height = `${e.currentTarget.scrollHeight <= 40 ? 40 : e.currentTarget.scrollHeight}px`
  }
  
  OBSERVER.add({
    name: 'textareaHeight',
    event: 'input',
    target: 'textarea',
    function: onInput
  })
  
  OBSERVER.on('textareaHeight')
}


// Création du custom select (doc: https://github.com/pytesNET/tail.select)
export const singleSelect = () => {
  const selector = '.js-single-select'
  if (!document.querySelector(selector)) return
  tail.select(selector, { animate: false })
}


// Création du custom select (doc: https://github.com/pytesNET/tail.select)
export const select = () => {
  if (!isMobile())
    tail.select('select', { animate: false })

  // Ajouter les images dans les options du Tail Select
  let selectBoxes, selectBoxesLength, i, j
  selectBoxes = document.querySelectorAll('.tail-select')
  selectBoxesLength = selectBoxes.length

  for (i=0; i<selectBoxesLength; i++){ //Looper dans chaques boites de sélections Tail Select
    var tailSelectItems = selectBoxes[i].querySelectorAll('.dropdown-option')
    var nativeSelect = selectBoxes[i].previousElementSibling
    var nativeSelectItems = nativeSelect.querySelectorAll('option:not(:first-child)')

    // Ajouter l'icone en symbole
    var svgElem = document.createElementNS('http://www.w3.org/2000/svg', 'svg'),
    useElem = document.createElementNS('http://www.w3.org/2000/svg', 'use')
    useElem.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', '/themes/core/assets/medias/images/icons/symbols.svg#ico-chevron')
    svgElem.appendChild(useElem);
    //selectBoxes[i].querySelector('.select-label').appendChild(svgElem)
    selectBoxes[i].appendChild(svgElem)

    for (j=0; j<nativeSelectItems.length; j++){ //Looper dans chaques item du Tail Select
      var imgPath = nativeSelectItems[j].dataset.image
      if((typeof imgPath !== "undefined") && imgPath !='' ){
          var newImage = document.createElement('img')
          newImage.src = imgPath
          tailSelectItems[j].classList.add('has-image')
          tailSelectItems[j].appendChild(newImage)
      }
    }
  }
}

export function validationEmail() {
  $.validator.addMethod('courriel', function(value, element) {
    return this.optional(element) || /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/.test(value)
  })
}

export function formatPhone(element) {
  $(element).mask('000 000-0000')
}

// Validation du formulaire de contact'
export function formContact() {
  validationEmail()
  formatPhone('#form-contact-phone')
  
  var m = getMessages()

  var rules = {
    name:          { required: true },
    city:          { required: true },
    email:         { required: true, courriel: true },
    phone:         { required: true, minlength: 12 },
    message:       { required: true }
  }
  var messages = {
    name:          { required: m.required },
    city:          { required: m.required },
    email:         { required: m.required, courriel: m.email },
    phone:         { required: m.required, minlength: m.phone },
    message:       { required: m.required }
  }
  
  formValidation('#form-contact', '#form-contact-submit', rules, messages)
}

// Validation du formulaire de contact'
export function formAgreed() {
  validationEmail()
  
  var m = getMessages()

  var rules = {
    name:          { required: true },
    contact:       { required: true },
    email:         { required: true, courriel: true },
    function:      { required: true },
    umqmember:     { required: true },
    file:          { required: true },
  }
  var messages = {
    name:          { required: m.required },
    contact:       { required: m.required },
    email:         { required: m.required, courriel: m.email },
    function:      { required: m.required },
    umqmember:     { required: m.required },
    file:          { required: m.required }
  }
  
  formValidation('#form-agreed', '#form-agreed-submit', rules, messages)
}

// LES MESSAGES
// Fonction contenant tous les messages
export function getMessages() {
  if ($('html')[0].lang == 'en') {
    return {
      required: 'This field is required.',
      select: 'Please chose an option.',
      email: 'Please enter a valid email address.',
      phone: 'Please enter a valid phone number.',
      postale: 'Please enter a valid ZIP code.',
      date: 'Please enter a valid date.',
      accept: 'The file must be a document pdf.',
      file: 'Please provide a adequate file.'
    }
  } else {
    return {
      required: 'Ce champ est obligatoire.',
      select: 'Veuillez sélectionner une option.',
      email: 'Veuillez fournir une adresse électronique valide.',
      phone: 'Veuillez fournir un numéro de téléphone valide.',
      postale: 'Veuillez fournir un code postal valide.',
      date: 'Veuillez fournir une date valide.',
      accept: 'Le fichier doit être un document pdf.',
      file: 'Veuillez lier le fichier adéquat pour ce champ.'
    }
  }
}

// LES VALIDATIONS __________
// Fonction exécutant la validation de chaque formulaire
export function formValidation(form, submit, rules, messages, handler) {
  $.validator.setDefaults({
    ignore: []
  })
  $(form).validate({
    debug: false,
    errorElement: 'div',
    focusInvalid: false,
    invalidHandler: function (form, validator) {
      if (!validator.numberOfInvalids())
        // eslint-disable-next-line semi
        return;
    
      const nbProjectError = validator.errorList.find(item => {
        return item.element.name === 'nbProject'
      })

      if(nbProjectError === undefined) {
        $('html, body').animate({
          scrollTop: $(validator.errorList[0].element).offset().top - 50
        }, 500)
      }
    },
    rules: rules,
    messages: messages,
    errorPlacement: function (error, element) {
      console.log()
      if (element.is(':radio')) {
        error.appendTo(element.parent().parent().find('p.field__error'))
      } else {
        error.appendTo(element.parent().find('p.field__error'))
      }
    },
    submitHandler: function () {
      if (typeof handler != 'undefined') {
        handler()
        return false
      }
      return true
    }
  })
  console.log($(submit))
  $(submit).click(function () {
    $(form).submit()
  })
}