/*
|--------------------------------------------------------------------------
| Transition avec barba et gestion des vues
|--------------------------------------------------------------------------
|
| C'est ici que les transitions entre les pages sont gérées, on y retrouve
| les différentes vues spécifiques à chaque page.
*/

import barba from '@barba/core'
import { Accordions } from './classes/Accordions.js'
import Overlay from '../libraries/overlay.js'
import { inputsAndTextareaLabel, textareaHeight, select, formContact, fileUpload, formAgreed } from './functions/form.js'
import { destroySlick, siteSlider, capsulesSlider } from './functions/sliders.js'
import { OBSERVER, SCROLLFIRE } from './main.js'
import { defaultTransition } from './transitions/default-transition.js'
import { fancyboxCapsule, initBackToTop, adjustButtonsMargins, addSvgToLinks, fixDocLinks, wrapYtVideo } from './functions/functions.js'
import { overlayMenu, overlayShare } from './functions/overlays.js'
import { removeHoverOnMobile, sizeH1 } from './functions/helper.js'
import { pageHome } from './pages/home.js'
import { pageTitle } from './pages/page-title.js'
import { components } from './pages/components.js'
import { newsList } from './pages/news-list.js'
import { newsDetail } from './pages/news-detail.js'

window.barba = barba

export const initAccordions = () => {
  if(!document.querySelector('.js-accordions')) return
  new Accordions()
}
export const initSelect = () => {
  if(!document.querySelector('.field--select')) return
  select()
}
export const initContact = () => {
  if(!document.querySelector('#form-contact')) return
  formContact()
}
export const initBarba = () => { 

  // Dans chaque beforeEnter hook
  barba.hooks.beforeEnter(({ current }) => {
    if (current.container != null)
      current.container.remove()
     
    fileUpload()
    removeHoverOnMobile()  
    textareaHeight()
    inputsAndTextareaLabel()
    initBackToTop()
    pageHome()
    SCROLLFIRE.init()
    initAccordions()
    initSelect()
    siteSlider()
    capsulesSlider()
    fancyboxCapsule()
    overlayMenu()
    overlayShare()
    sizeH1()
    pageTitle()
    components()
    newsList()
    newsDetail()
    adjustButtonsMargins()
    addSvgToLinks()
    initContact()
    fixDocLinks()
    wrapYtVideo()
    formAgreed()
  })

  // Dans chaque afterLeave hook
  barba.hooks.afterLeave(() => {
    OBSERVER.allOff()
    OBSERVER.removeAll()
    Overlay.destroyAll()
    destroySlick()
    SCROLLFIRE.destroy()
  })

  barba.hooks.enter ((data) => {
    var ogUrl = data.next.html.match(/<meta.*property="og:url".*content="(.*)".*\/>/i)[1]
    var ogImage = data.next.html.match(/<meta.*property="og:image".*content="(.*)".*\/>/i)[1]
    var ogTitle = data.next.html.match(/<meta.*property="og:title".*content="(.*)".*\/>/i)[1]
    var ogDescription = data.next.html.match(/<meta.*property="og:description".*content="(.*)".*\/>/i)[1]
    document.querySelector('meta[property="og:url"]').setAttribute('content',ogUrl)
    document.querySelector('meta[property="og:image"]').setAttribute('content',ogImage)
    document.querySelector('meta[property="og:title"]').setAttribute('content',ogTitle)
    document.querySelector('meta[property="og:description"]').setAttribute('content',ogDescription)
  })

  barba.init({

    // Options
    debug: true,
    timeout: 5000,
    prevent: ({ el }) => el.classList && el.classList.contains('js-barba-prevent'),

    // Transitions de page
    transitions: [
      defaultTransition()
    ],
  })
}
