import anime from 'animejs/lib/anime.es.js'

export const newsDetail = () => {
    if (!document.querySelector('.js-news-detail-page')) return

  // La shape de la section actus similaires
  const similarNewsShapeStates = [
    { d: "M 115.1 13.2 C 104.7 4.6 92.1 -0.4 76.5 2.7 C 71 4.3 63.7 10.2 55.9 13 C 41.5 23.4 16 18.1 10.9 36 C 8.7 44 9.5 52.4 10.7 60.5 C 14.6 87.3 -7.3 121.7 20.3 132.1 C 34.1 137.4 63.7 147.1 80.4 153.4 C 100.1 156.9 148.8 165.5 160.1 147.1 C 173.2 125.5 159.5 93.6 150 73.3 C 143.2 59.3 127.3 23.5 115.1 13.2 Z" },
    { d: "M 78.9 1.9 C 64.6 -1.7 49.8 -0.7 35.8 9.4 C 29 14.3 24.3 21.6 20.2 28.9 C 10.8 45.6 -2.8 70.2 0.5 90 C 2 98.8 6.8 106.6 11.8 113.9 C 28.2 138.3 49.1 162.6 81.3 160.1 C 97.5 158.9 112.8 152.1 127.1 144.2 C 146 133.8 167.5 118.4 170 95.2 C 172.9 68 144.3 43.2 125.4 27.9 C 112.1 17.3 95.9 6.3 78.9 1.9 Z" }
  ]

  anime({
    targets: '.similar-news__shape path',
    d: [
      { value: similarNewsShapeStates[0].d },
      { value: similarNewsShapeStates[1].d }
    ],
    duration: 10000,
    direction: 'alternate',
    easing: 'easeInOutSine',
    autoplay: true,
    loop: true
  });
}